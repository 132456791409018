.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.question-section {
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    border-radius: 8px;
  }
}
.illustration {
  &.hide {
    display: none;
    position: absolute;
  }
  &.show {
    display: block;
    position: initial;
    transition: ease 0.1s;
  }
}
input:focus{
  box-shadow: none !important;
}
.quiz-sections{
  &::-webkit-scrollbar {
    width: 20px;
    border-radius: 20px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: #273F94;
    border-radius: 20px;
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #5171E3;
    border-radius: 20px;
  }

  @media only screen and (max-width: 550px){
    &::-webkit-scrollbar {
      width: 0;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }
}

.leaderboard{
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    margin: 0;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #273F94;
    border-radius: 5px;
    margin: 0;
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #5171E3;
    border-radius: 5px;
    margin: 0;
  }
}
